import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import every from "lodash/every";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { useEffect } from "react";
import { type Control, Controller, type FieldErrors, useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateEvent } from "features/events";

// ------------------------------------
// Configuration
// ------------------------------------

interface DataToolOption {
  label: string;
  value:
    | "data_tool_no_idea"
    | "looker_studio"
    | "power_bi"
    | "tableau"
    | "google_sheets"
    | "excel"
    | "data_tool_other";
}

const dataToolOptions: DataToolOption[] = [
  { label: "I don't know yet", value: "data_tool_no_idea" },
  { label: "Looker Studio", value: "looker_studio" },
  { label: "Power BI", value: "power_bi" },
  { label: "Tableau", value: "tableau" },
  { label: "Google Sheets", value: "google_sheets" },
  { label: "Excel", value: "excel" },
  { label: "Some other data tool", value: "data_tool_other" },
];

const expectations = [
  {
    label: "I want easy access to my data from my data tool. I'll build my own reports.",
    value: "data_only",
  },
  {
    label: "I want reporting templates to get started. I'll customize my reports from there.",
    value: "report_templates",
  },
  {
    label: "I would like QuickBI consultants to build me the data reports I need.",
    value: "consultancy",
  },
  {
    label: "Something else.",
    value: "other",
  },
];

const roleOptions = [
  { label: "Business analyst", value: "business_analyst" },
  { label: "Data analyst", value: "data_analyst" },
  { label: "Data engineer", value: "data_engineer" },
  { label: "Data scientist", value: "data_scientist" },
  { label: "Management", value: "management" },
  { label: "Controller", value: "controller" },
  { label: "Marketing", value: "marketing" },
  { label: "Sales", value: "sales" },
  { label: "Developer", value: "developer" },
  { label: "Academic (student or professor)", value: "academic" },
  { label: "Other", value: "other" },
];

// Some notes on the schema definitions:
// -------------------------------------
// 1. Schema needs to be specified non-dynamically for the superRefine to work.
//    So looping over the dataToolOptions and creating the schema dynamically did not work properly.
//
// 2. We need to define schemas seperately so the validation of single fields and
//    superRefines can be done simultaneously.
//    Otherwise we would get error messages from superRefines only after single fields are
//    validated to contain no errors.
//    More info here: https://github.com/colinhacks/zod/issues/479#issuecomment-1843302409
//
// 3. We use snake_case for values and field names for readability and consistency in PostHog and reporting.

const dataToolOptionsSchema = z
  .object({
    data_tool_no_idea: z.boolean().optional(),
    looker_studio: z.boolean().optional(),
    power_bi: z.boolean().optional(),
    tableau: z.boolean().optional(),
    google_sheets: z.boolean().optional(),
    excel: z.boolean().optional(),
    data_tool_other: z.boolean().optional(),
    other_data_tool_name: z.string().optional(),
  })
  .superRefine((values, ctx) => {
    if (every(dataToolOptions, (option) => !values[option.value])) {
      ctx.addIssue({
        message: "Please select at least one option",
        code: z.ZodIssueCode.custom,
        path: ["dataToolOptions"],
      });
    }
    if (values.data_tool_other && !values.other_data_tool_name) {
      ctx.addIssue({
        message: "Please specify the name of the data tool you are planning to use",
        code: z.ZodIssueCode.custom,
        path: ["other_data_tool_name"],
      });
    }
  });

const expectationsSchema = z
  .object({
    expectations: z.string(),
    other_expectations: z.string().optional(),
  })
  .superRefine((values, ctx) => {
    if (values.expectations === "other" && !values.other_expectations) {
      ctx.addIssue({
        message: "Please specify your use case for QuickBI",
        code: z.ZodIssueCode.custom,
        path: ["other_expectations"],
      });
    }
  });

const roleSchema = z
  .object({
    role: z.string(),
    other_role: z.string().optional(),
  })
  .superRefine((values, ctx) => {
    if (values.role === "other" && !values.other_role) {
      ctx.addIssue({
        message: "Please specify your role",
        code: z.ZodIssueCode.custom,
        path: ["other_role"],
      });
    }
  });

// Combine the schemas
const validationSchema = z.intersection(
  dataToolOptionsSchema,
  z.intersection(expectationsSchema, roleSchema),
);

export type OnboardingQuestionnaireData = z.infer<typeof validationSchema>;

interface CustomFormErrors extends FieldErrors<OnboardingQuestionnaireData> {
  dataToolOptions?: {
    type: string;
    message: string;
  };
}

// ------------------------------------
// Helpers
// ------------------------------------

function getLabelFromOptions(options: { label: string; value: string }[], value: string) {
  return options.find((option) => option.value === value)?.label;
}

function parseAnswers(data: OnboardingQuestionnaireData) {
  const selectedDataTools = dataToolOptions
    .map((option) => {
      if (data[option.value]) {
        return option.label;
      }
    })
    .filter(Boolean);
  let parsedDataTools = selectedDataTools.join(", ");
  if (parsedDataTools.includes("Some other data")) {
    parsedDataTools = `${parsedDataTools}: ${data.other_data_tool_name}`;
  }

  const parsedExpectations =
    data.expectations === "other"
      ? data.other_expectations
      : getLabelFromOptions(expectations, data.expectations);
  const parsedRole =
    data.role === "other" ? data.other_role : getLabelFromOptions(roleOptions, data.role);

  const parsed = {
    ...data,
    parsedDataTools,
    parsedExpectations,
    parsedRole,
  };

  return parsed;
}

// ------------------------------------
// Component
// ------------------------------------

interface OnboardingQuestionnaireProps {
  isSaving: boolean;
  onSkip: () => void;
  onSubmit: (data: OnboardingQuestionnaireData) => void;
}

export function OnboardingQuestionnaire({
  isSaving,
  onSkip,
  onSubmit,
}: OnboardingQuestionnaireProps): React.ReactElement {
  const createEvent = useCreateEvent();
  const { control, handleSubmit, formState, register, unregister, watch } =
    useForm<OnboardingQuestionnaireData>({
      resolver: zodResolver(validationSchema),
    });

  const errors: CustomFormErrors = formState.errors;

  // Watch form fields to conditionally show "other" text fields
  const watchDataToolOther = watch("data_tool_other");
  const showOtherDataToolField = watchDataToolOther;

  const watchExpectations = watch("expectations");
  const showOtherExpectationsField = watchExpectations === "other";

  const watchRole = watch("role");
  const showOtherRoleField = watchRole === "other";

  // Register and unregister "other" text fields based on the watch values
  useEffect(() => {
    if (showOtherDataToolField) {
      register("other_data_tool_name");
    } else {
      unregister("other_data_tool_name");
    }
  }, [register, unregister, showOtherDataToolField]);

  useEffect(() => {
    if (showOtherExpectationsField) {
      register("other_expectations");
    } else {
      unregister("other_expectations");
    }
  }, [register, unregister, showOtherExpectationsField]);

  useEffect(() => {
    if (showOtherRoleField) {
      register("other_role");
    } else {
      unregister("other_role");
    }
  }, [register, unregister, showOtherRoleField]);

  function getFormErrorMessage(name: keyof CustomFormErrors): React.ReactElement | undefined {
    if (!errors[name]) {
      return undefined;
    }
    return <small className="p-error block">{errors[name]?.message}</small>;
  }

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(parseAnswers(data)))} className="p-fluid">
      {/* ---------- */}
      {/* Question 1 */}
      {/* ---------- */}

      <div className="field">
        <label
          htmlFor="dataToolOptions"
          className={clsx("font-semibold", { "p-error": errors.dataToolOptions })}
        >
          1. What data tool are you planning to use with QuickBI? (select all that apply)
        </label>
        <div className="grid grid-cols-2 gap-x-6">
          {dataToolOptions.map((option) => (
            <Controller
              name={option.value}
              key={option.value}
              control={control}
              rules={{ required: "Value is required." }}
              render={({ field, fieldState }) => (
                <div className="mt-4 space-y-4">
                  <div className="align-items-center flex">
                    <Checkbox
                      inputId={option.value}
                      checked={field.value ?? false}
                      inputRef={field.ref}
                      className={clsx({ "p-invalid mr-1": fieldState.error })}
                      onChange={(e) => field.onChange(e.checked)}
                    />
                    <label htmlFor={option.value} className="ml-2">
                      {option.label}
                    </label>
                  </div>
                </div>
              )}
            />
          ))}
        </div>

        {getFormErrorMessage("dataToolOptions")}
      </div>

      {/* Specify other */}
      {/* ------------- */}

      {showOtherDataToolField ? (
        <OtherTextField
          name="other_data_tool_name"
          label="Which other data tool?"
          control={control}
          errors={errors}
        />
      ) : null}

      {/* ---------- */}
      {/* Question 2 */}
      {/* ---------- */}

      <div className="field mt-8 border-t border-gray-200 pt-8">
        <label
          htmlFor="expectations"
          className={clsx("font-semibold", { "p-error": errors.expectations })}
        >
          2. Which of the following best describes your use case for QuickBI?
        </label>
        <Controller
          name="expectations"
          control={control}
          rules={{ required: "Value is required." }}
          render={({ field }) => (
            <div className="mt-4 space-y-4">
              {expectations.map((option) => (
                <div className="align-items-center flex" key={option.value}>
                  <RadioButton
                    inputId={option.value}
                    {...field}
                    inputRef={field.ref}
                    value={option.value}
                    checked={field.value === option.value}
                  />
                  <label htmlFor={option.value} className="ml-2">
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          )}
        />
        {getFormErrorMessage("expectations")}
      </div>

      {/* Specify other */}
      {/* ------------- */}

      {showOtherExpectationsField ? (
        <OtherTextField
          name="other_expectations"
          label="Your use case for QuickBI"
          control={control}
          errors={errors}
        />
      ) : null}

      {/* ---------- */}
      {/* Question 3 */}
      {/* ---------- */}

      <div className="field mt-8 border-t border-gray-200 pt-8">
        <label htmlFor="role" className={clsx("font-semibold", { "p-error": errors.role })}>
          3. Which option best describes your current role?
        </label>
        <Controller
          name="role"
          control={control}
          rules={{ required: "Please select a role" }}
          render={({ field }) => (
            <div className="mt-4 grid grid-cols-2 gap-x-6 gap-y-4">
              {roleOptions.map((option) => (
                <div className="align-items-center flex" key={option.value}>
                  <RadioButton
                    inputId={option.value}
                    {...field}
                    inputRef={field.ref}
                    value={option.value}
                    checked={field.value === option.value}
                  />
                  <label htmlFor={option.value} className="ml-2">
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          )}
        />

        {getFormErrorMessage("role")}
      </div>

      {/* Specify other */}
      {/* ------------- */}

      {showOtherRoleField ? (
        <OtherTextField name="other_role" label="Your role" control={control} errors={errors} />
      ) : null}

      <Button
        type="submit"
        label={isSaving ? "Starting..." : "Start connecting data sources"}
        className="p-button mt-8"
        disabled={isSaving}
        onClick={() =>
          createEvent.mutate({ name: "onboarding_questionnaire_submit_button_clicked" })
        }
      />
      <Button
        label="Skip questions"
        className="p-button mt-4"
        disabled={isSaving}
        onClick={onSkip}
        severity="secondary"
        outlined
      />
    </form>
  );
}

interface OtherTextFieldProps {
  label: string;
  name: "other_data_tool_name" | "other_expectations" | "other_role";
  control: Control<OnboardingQuestionnaireData>;
  errors: CustomFormErrors;
}

function OtherTextField({ label, name, control, errors }: OtherTextFieldProps): React.ReactElement {
  return (
    <div className="field mb-10">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label
              htmlFor={field.name}
              className={clsx({ "p-error": errors.other_data_tool_name })}
            >
              {label}
            </label>
            <InputText
              id={field.name}
              value={field.value ?? ""}
              className={clsx({ "p-invalid": fieldState.error })}
              onChange={(e) => field.onChange(e.target.value)}
            />
            {!!errors[field.name] && (
              <small className="p-error block">{errors[field.name]?.message}</small>
            )}
          </>
        )}
      />
    </div>
  );
}
